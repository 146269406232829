<template>
<b-container fluid>
    <b-row>
        <b-col md="12">
            <iq-card>
                <template v-slot:body>
                    <b-row>
                        <b-col lg="12">
                            <DtInvoice ref="dt-invoices" @onAddInvoice="onAddInvoice" @onShowInvoiceHistories="onShowInvoiceHistories" @onRefund="onRefund" @onEditInvoice="onEditInvoice"/>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </b-col>
        <b-col md="12">
            <b-modal id="modal-refund" ref="refund" :title="refund.title" :hide-footer=true>
                <refundForm :invoice="refund.item" :all="refund.all" ref="refund" @onRefunded="onRefunded" />
            </b-modal>
        </b-col>
        <b-col md="12">
            <b-modal id="modal-time-line" size="lg" ref="modal-timeLine" title="Activity timeline" :hide-footer=true>
                <timeLine :paymentLogs="paymentLogs" :invoiceLogs="invoiceLogs" @onGetAll="allInvoiceLogs"></timeLine>
            </b-modal>
        </b-col>
        <b-col md="12">
            <b-modal id="modal-add-invoice" ref="modal-newInvoice" :title="addOrEditTitle" :hide-footer=true>
                <addInvoiceForm :invoice="invoice" :finish="onFinish" @onAccountNull="onAccountNull" @onCreateInvoice="createInvoice" @onUpdateInvoice="onUpdateInvoice"></addInvoiceForm>
            </b-modal>
        </b-col>
            
    </b-row>
</b-container>
</template>

<script>
import {
    sofbox
} from "@/config/pluginInit";

import DtInvoice from "./children/DtInvoice.vue";
import addInvoiceForm from "./children/newInvoice.vue";
import refundForm from "./children/refundForm.vue";
import timeLine from "./children/timeLine.vue";
import Spinner1 from '@/components/loaders/spinner1'
import _ from 'lodash'
import api from '@/api/RestClient'
import {services} from '@/helpers'
import {
    mapGetters
} from 'vuex'

export default {
    name: "UiDataTable",
    components: {
        DtInvoice,
        Spinner1,
        refundForm,
        timeLine,
        addInvoiceForm
    },
    created() {
        let _url = window.location.href.replace('127.0.0.1', 'localhost')
        if (window.location.host.split(":")[0] == '127.0.0.1') window.location.href = _url
        this.isAdmin = this.admin;
    },
    mounted() {
        sofbox.index();
    },
    computed: {
        ...mapGetters({
            admin: 'Auth/isAdmin',
            client: 'Auth/isClient',
        })
    },
    methods: {
        allInvoiceLogs() {
            api.invoices.get(this.invoice.id).then(response => {
                this.invoiceLogs = response.data[0].logs;
                this.paymentLogs = response.data[0].payment_logs;
                
            }).catch(err => {
                console.log(err);
            }).finally(() => {
            })
        },
        onAccountNull(message) {
            this.$bvToast.toast(message, {
                title:  'Error',
                variant: 'danger',
            });
        },
        onShowInvoiceHistories(item) {
            this.invoice.id = item.id;
            this.invoiceLogs = item.logs;
            this.paymentLogs = item.payment_logs;
            this.$refs['modal-timeLine'].show();
        },
        onAddInvoice() {
            this.invoice = {};
            this.addOrEditTitle = 'Add invoice'
            this.$refs['modal-newInvoice'].show();
        },
        onEditInvoice(item) {
            this.addOrEditTitle = 'Update invoice'
            this.invoice_id = item.id
            let invoice = {
                'description': item.description,
                'account': {
                    code: item.account_id,
                    name: item.account
                },
                'status': item.state,
                'id': item.id,
                'due_date': item.due_date,
                'date': item.date,
                'total_refund': item.total_refund,
            }
            this.invoice = invoice;
            this.$refs['modal-newInvoice'].show();
        },
        onUpdateInvoice(item) {
            api.invoices.update(this.invoice.id, item)
                .then(response => {
                    this.$bvToast.toast(response.message, {
                        title: 'Success',
                        variant: 'success',
                    });
                    this.$refs['dt-invoices'].$refs['dt-invoices'].forceRenderTable()
                })
                .catch(err => {
                     this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.$refs['modal-newInvoice'].hide();
                })
        },
        onRefund(item) {
            this.refund.item = item;
            this.refund.title = `Refund invoice #${item.id}`
            this.refund.all = true;
            if(item.total_refund != 0) this.refund.all = false
            this.$refs.refund.show();
        },
        onRefunded(event) {
            this.$bvToast.toast(event.message, {
                title: event.status == 0 ? 'Error' : 'Success',
                variant: event.status == 0 ? 'danger' : 'success',
            });
            if (event.status != 0) {
                this.$refs['dt-invoices'].$refs['dt-invoices'].forceRenderTable()
                this.$refs.refund.hide();
            }
        },
        createInvoice(item) {
            api.invoices.post(item)
                .then(response => {
                  this.$bvToast.toast(response.message, {
                        title: 'Success',
                        variant:'success',
                  });
                 this.$refs['dt-invoices'].$refs['dt-invoices'].forceRenderTable()
                })
                .catch(err => {
                     this.$bvToast.toast(err.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                      this.$refs['modal-newInvoice'].hide();
                })
        }
    },
    watch: {
    },
    data() {
        return {
            isAdmin: false,
            columns: [],
            rows: [],
            dates: {
                to: null,
                from: null,
            },
            accountId: 3,
            submitload: false,
            charts: {},
            refund: {
                title: '***',
                item: {},
                all: true
            },
            onFinish: false,
            invoice: {},
            addOrEditTitle: '***',
            invoiceLogs: [],
            paymentLogs: []
        };
    },
};
</script>

<style>
#myTabContent-5 {
    padding-bottom: 2em !important;
    /* margin: 0 5px; */
}

.actionClass {
    width: 18%;
}

.timeClass,
.createdatClass,
.accountClass {
    width: 10%;
}

textarea.form-control {
    line-height: 25px;
}

.cursor-default {
    cursor: default;
}

.table th,
.table td {
    padding: 0.55rem;
    vertical-align: top;
}

.action {
    width: 100%;
    height: 43.5px;
}
</style>
