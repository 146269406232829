<template>

    <b-modal id="modal-new-account" ref="account" hide-footer :title="title">
        <b-form @submit="onSubmit">

            <b-row>
                <b-col lg="6" md="6">
                    <b-form-group>
                        <div
                            class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                                <label class="mr-2">Status</label>
                                <input type="checkbox" class="custom-control-input bg-primary" v-model="form.status"
                                    id="customSwitch-13">
                                <label class="custom-control-label" for="customSwitch-13" data-on-label="On"
                                    data-off-label="Off">
                                </label>
                            </div>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-form-group id="input-group-1" label="Name:" label-for="input-1" description="">
                <b-form-input id="input-1" v-model="form.name" type="text" placeholder="Account name"
                    required></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Account Type:" label-for="input-4" description="">
                <select v-model="form.type" class="form-control" placeholder="kka">
                    <option value="null">Please select account type</option>
                    <option v-for="( type, type_id ) in types()" :key="type.name" :value="type_id">{{ type.name }}
                    </option>
                </select>
            </b-form-group>
            <div class="mb-4" v-show="is_taxable">
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="gst_tax_title">GST Tax:</label>
                        <label for="gst_tax_value"><b-form-checkbox v-model="form.is_exempt_gst_tax" switch
                                :checked="form.is_exempt_gst_tax" size="sm">is exempt?</b-form-checkbox></label>
                    </div>
                    <b-form-input id="input-gst-tax" v-model="form.gst_tax_num" type="text"
                        placeholder="GST Tax"></b-form-input>
                </div>
                <div class="form-group">
                    <div class="d-flex justify-content-between">
                        <label for="qst_tax_title">QST Tax:</label>
                        <label for="qst_tax_value"><b-form-checkbox v-model="form.is_exempt_qst_tax" switch
                                :checked="form.is_exempt_qst_tax" size="sm">is exempt?</b-form-checkbox></label>
                    </div>
                    <b-form-input id="input-qst-tax" v-model="form.qst_tax_num" type="text"
                        placeholder="QST Tax"></b-form-input>
                </div>

            </div>
            <b-form-group id="input-group-4" label="Discounts:" label-for="input-4" description="" v-if="false">
                <Discounts :_discounts="form.discounts" @onSelect="onSelectDiscounts"></Discounts>
            </b-form-group>

            <b-row align-h="end" class="px-4">
                <b-button variant="primary" type="submit" :disabled="isLoading">
                    Submit
                    <b-spinner small type="grow" v-show="isLoading"></b-spinner>
                </b-button>
            </b-row>
        </b-form>
    </b-modal>

</template>
<script>
import { ACCOUNT_TYPE } from '@/constantes';
import Discounts from '../children/discounts.vue';
import api from '@/api/RestClient';

export default {
    name: 'NewAccountModal',
    components: {
        Discounts
    },
    props: {
        form: {
            type: Object,
            default: {}
        },
        accountId: {
            type: Number,
            default: null
        },
        newAccount: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Add new account'
        }
    },
    data() {
        return {
            isLoading: false,
            is_taxable: false,
            modal: {
                title: 'Add new Account',
            },
        }
    },
    methods: {
        types() {
            return ACCOUNT_TYPE;
        },
        onSelectDiscounts(discounts) {
            this.form.discounts = discounts;
        },
        onSubmit(e) {

            e.preventDefault();

            this.isLoading = true;

            const accountId = this.accountId;
            const data = this.form;

            if (this.newAccount) {

                api.accounts.post(data).then(response => {
                    let variant = 'danger';
                    let message = 'Something went wrong'


                    if ('success' in response) {

                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        // this.toast('account', variant, message);
                        this.$bvToast.toast(message, {title: 'account', variant: variant })

                        if (response.success) {

                            this.fetchAccounts();

                            this.form = {
                                "name": null,
                                "type": null,
                                "status": null,
                                "discounts": []
                            };

                            // this.$refs['account'].hide();
            				// this.$root.$emit('bv::hide::modal', 'modal-new-account')
            				this.$root.$emit('ibg:fetch:accounts')

                        }
                    }
                }).catch(error => {
                    console.log(error);
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.isLoading = false;
                    this.$refs['account'].hide();
                })


            } else {


                api.accounts.update(accountId, data).then(response => {

                    if ('success' in response) {

                        const variant = response.success ? 'success' : 'danger';
                        const message = response.message;

                        // this.toast('Account', variant, message);
                        this.$bvToast.toast(message, {title: 'Account', variant: variant })

                        if (response.success) {

                            // this.fetchAccounts();

            				this.$root.$emit('ibg:fetch:accounts')
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.isLoading = false;
                    this.$refs['account'].hide();
                });

                // this.newAccount = true;
                // this.modal.title = "Add new account";

            }
        },
    }
}
</script>