
<template>
    <b-container fluid>

            <b-col sm="12" >
                <iq-card class="p-2">

                    <template v-slot:headerTitle>
                        <h4 class="card-title">Accounts</h4>
                    </template>

                     <template v-if="isAdmin" v-slot:headerAction>
                        <b-button v-b-modal.modal-new-account variant="primary" @click="add_new_account=true" class="text-right mr-1">
                            Add Account
                        </b-button>
                    </template>

                    <template v-slot:body>

                            <!-- START FILTER OPTIONS ROW  -->
                            <b-row class="my-2" align-h="between">

                                <!-- START Sow Option Input -->
                                <b-col md="2" >
                                    <b-form-group label-for="per-page-select" label-align-sm="left" class="mb-0">
                                        <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                                <!-- END Sow Option Input -->


                                <!-- START Filter Input -->
                                <b-col md="4">
                                    <b-form-group class="mb-0" >

                                        <b-input-group >

                                            <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search" ></b-form-input>

                                        </b-input-group>

                                    </b-form-group>

                                </b-col>
                                <!-- END Filter Input -->

                            </b-row>
                            <!-- END FILTER OPTIONS ROW  -->


                            <!-- DataTable -->
                            <AccountsTable @showDetails="showDetails" @editAccount="edit"></AccountsTable>


                    </template>
                </iq-card>
            </b-col>

            <!-- START Subscription or Balances Card  -->
            <b-col sm="12" v-if="detailed_component != null && selected_account != null " ref="account_details">

                <component :is="detailed_component" :account="selected_account"></component>

            </b-col>
            <!-- START Subscription or Balances Card  -->


        <!-- Add/Edit Account Modal-->
        <NewAccountModal ref="account-modal" v-bind="{
            form, 
            newAccount: add_new_account, 
            accountId: account_id,
            title: add_new_account ? 'Add new Account' : 'Edit Account'}"></NewAccountModal>

    </b-container>
</template>
<script>
import { sofbox } from '../../config/pluginInit'
import api from '@/api/RestClient';
import AccountsTable from './tables/AccountsTable.vue';

import Balances from './widgets/Balances.vue'
import Discount from './widgets/Discount.vue'
import Subscriptions from './widgets/Subscriptions.vue'
import StatusCell from '@/components/inboxgeek/tables/StatusCell.vue'

import { ACCOUNT_TYPE } from '../../constantes';
import discounts from './children/discounts.vue';
import { mapGetters } from 'vuex';
import NewAccountModal from './modals/NewAccountModal'


export default {
    name: 'accounts',
    components: {
        AccountsTable,
        Balances,
        Subscriptions,
        StatusCell,
        discounts,
        Discount,
        NewAccountModal
    },
     computed: {
        ...mapGetters({
            isAdmin: 'Auth/isAdmin',
            isClient: 'Auth/isClient',
            isAccounting: 'Auth/isAccounting',
            user: 'Auth/user',
            liveOpenersSubscribed: 'Auth/liveOpenersSubscribed',
            liveLeadsSubscribed: 'Auth/liveLeadsSubscribed',
        })
    },
    mounted() {

        sofbox.index()
        window.parent = this;

    },

    data() {
        return {

            detailed_component:null,
            selected_account:null,

            loading : {
                modal: false,
                table : false,
            },

            headerTitle: 'Account list',

            notOrderable: [],
            columnsTable: null,

            filter: null,
            account_id: null,

            add_new_account:true,
            accounts : [],

            form: {
               "name": null,
               "type": null,
               "status": false,
               "discounts": [],
               "gst_tax_num": null,
               "qst_tax_num": null,
            },
            modal: {
                title: 'Add new Account',
            },
            table: null,
            perPage: 25,
            pageOptions: [25, 50, { value: 999, text: "Show all" }],
            is_taxable: false,
        }
    },
    methods: {
        showDetails({component, account}) {

            account.isLoading = true;
            this.detailed_component = component;
            this.selected_account   = account;


            setTimeout( () => {

                const el = this.$refs.account_details;

                if (el) {

                    el.scrollIntoView({behavior: 'smooth'});

                }

                let item = _.find(this.accounts, (o) => { return o.id == account.id})
                if(item) {
                    item.isLoading = false;

                    this.loading.table = true;
                    this.$nextTick(() => {
                        this.loading.table = false;
                    });

                }

            }, 500);



        },
        edit(account) {

            this.add_new_account = false;
            this.modal.title = "Edit account";
            this.account_id = account.id;

            let discounts = [];
            account.discounts.forEach(discount => {
                discounts.push({
                    code: discount.id,
                    name: discount.name
                });
            });
            this.form = {
                "name": account.name,
                "type": account.type,
                "status": account.status,
                "discounts": discounts,
                "gst_tax_num": account.gst_tax_num,
                "qst_tax_num": account.qst_tax_num,
                "is_exempt_gst_tax": account.is_exempt_gst_tax ? true : false,
                "is_exempt_qst_tax": account.is_exempt_qst_tax ? true : false
            };
            this.is_taxable = ['CA'].includes(account.billing_address_country)
            this.$refs['account-modal'].$refs['account'].show()

        },
        onSubmit(e) {

            e.preventDefault();

            this.loading.modal = true;

            const account_id = this.account_id;
            const data = this.form;

            if(this.add_new_account)
            {

                api.accounts.post(data).then(response => {
                    let variant = 'danger';
                    let message = 'Something went wrong'


                    if ('success' in response) {

                        variant = response.success ? 'success' : 'danger';
                        message = response.message;
                        this.toast('account', variant, message);

                        if (response.success){

                            this.fetchAccounts();

                            this.form = {
                                "name":     null,
                                "type":     null,
                                "status":   null,
                                "discounts": []
                            };

                            this.$refs['account-modal'].$refs['account'].hide();
                        }
                    }


                }).catch(error => {
                    console.log(error);
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.loading.modal = false;
                })


            }else{


                api.accounts.update(account_id, data).then(response => {

                    if ('success' in response) {

                        const variant = response.success ? 'success' : 'danger';
                        const message = response.message;

                        this.toast('Account', variant, message);
                        if (response.success){

                            this.fetchAccounts();

                            this.$refs['account-modal'].$refs['account'].hide();
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                    });
                }).finally(() => {
                    this.loading.modal = false;
                });


                this.add_new_account = true;
                this.modal.title = "Add new account";

            }




        },
        setNotOrderableColumns() {
            let n = this.columns.length
            for (let index = 0; index < n; index++) {
                if (index != this.filter) this.notOrderable.push(index)
            }
        },
        toast(title = "success", variant = "success", message = "Organization updated successfully") {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant
            })
        },
        // table :
        onSelectDiscounts(discounts) {
            this.form.discounts = discounts;
        },
    },
    watch: {
        filter: {
            handler(newVal, oldVal) {
                this.$root.$emit('accounts:filter', newVal);
            },
        },
    }
}

</script>

<style>
.dataTables_filter > label {
  width: 50% !important;
  text-align: left;
}
.dataTables_filter {
  text-align: right;
}
ul.pagination{
  justify-content: end;;
}
.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgb(171 216 225 / 15%);
}
</style>
